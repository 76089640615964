import React from "react";
import {
  AuditOutlined,
  CarOutlined,
  CloudDownloadOutlined,
  CodeSandboxOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  HomeOutlined,
  InboxOutlined,
  PercentageOutlined,
  PrinterOutlined,
  SettingOutlined,
  ShopOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";

import NotFoundPage from "./pages/404Page";

import DashboardLayout from "./layouts/dashboard";
import Login from "./pages/login";
import RegisterPage from "./pages/register";
import DirectUse from "./pages/directUse";

const HomePageComponent = React.lazy(() => import("./pages/homePage"));
const LocationsPageComponent = React.lazy(() => import("./pages/locations"));
const BuildingsPageComponent = React.lazy(() => import("./pages/buildings"));
const ShelvesPageComponent = React.lazy(() => import("./pages/shelves"));
const ZonesPageComponent = React.lazy(() => import("./pages/zones"));
const BinsPageComponent = React.lazy(() => import("./pages/bins"));
const ProductsPageComponent = React.lazy(() => import("./pages/products"));
const DeliveryLoadsPageComponent = React.lazy(() =>
  import("./pages/deliveryLoads")
);
const VendorsPageComponent = React.lazy(() => import("./pages/vendors"));
const ProductDiscountRulesPageComponent = React.lazy(() =>
  import("./pages/productDiscountRules")
);
const InventoryGroupsPageComponent = React.lazy(() =>
  import("./pages/inventoryGroups")
);
const ReceivingItemsPageComponent = React.lazy(() =>
  import("./pages/receivingItems")
);
const PalletsPageComponent = React.lazy(() => import("./pages/pallets"));
const InventoryZPLSettingsPageComponent = React.lazy(() =>
  import("./pages/inventoryZplSettings")
);
const DiscountPricingRulesPageComponent = React.lazy(() =>
  import("./pages/discountPricingRules")
);
const OutgoingTrucksPageComponent = React.lazy(() =>
  import("./pages/outgoingTruck")
);
const InventoryGroupSummaryView = React.lazy(() =>
  import("./pages/inventoryGroupSummaryView")
);
const InventorySettingsPageComponent = React.lazy(() =>
  import("./pages/inventorySettings")
);
const InventoryProductTypesPageComponent = React.lazy(() =>
  import("./pages/inventoryProductTypes")
);
const InventoryItemFieldConfigsPageComponent = React.lazy(() =>
  import("./pages/inventoryItemFieldConfigs")
);

export const INDEX_ROUTE = "/";
export const REGISTER_ROUTE = "/register";
export const LOCATIONS_ROUTE = "/locations";
export const BUILDINGS_ROUTE = "/buildings";
export const SHELVES_ROUTE = "/shelves";
export const ZONES_ROUTE = "/zones";
export const BINS_ROUTE = "/bins";
export const PRODUCTS_ROUTE = "/products";
export const DELIVERY_LOADS_ROUTE = "/received-trucks";
export const VENDORS_ROUTE = "/vendors";
export const PRODUCT_DISCOUNT_RULES_ROUTE = "/product-discount-rules";
export const INVENTORY_GROUPS_ROUTE = "/inventory-groups";
export const PALLETS_ROUTE = "/pallets";
export const RECEIVING_ROUTE = "/pre-process-inventory";
export const INVENTORY_ZPL_SETTINGS_ROUTE = "/inventory-zpl-settings";
export const DISCOUNT_PRICING_RULES_ROUTE = "/discount-pricing-rules";
export const OUTGOING_TRUCKS_ROUTE = "/outgoing-trucks";
export const INVENTORY_GROUP_SUMMARY_VIEW_ROUTE =
  "/inventory-group-summary-view/:inventoryGroupId";
export const INVENTORY_SETTINGS_ROUTE = "/inventory-settings";
export const INVENTORY_PRODUCT_TYPES_ROUTE = "/product-types";
export const INVENTORY_ITEM_FIELD_CONFIGS_ROUTE =
  "/inventory-item-field-configs";
export const DIRECT_USE_ROUTE = "/direct-use-adapter/:jwt/:adminJwt";

export const NON_LOGIN_ROUTES = [
  {
    exact: true,
    name: "login",
    path: INDEX_ROUTE,
    component: Login,
  },
  {
    exact: true,
    name: "login",
    path: DIRECT_USE_ROUTE,
    component: DirectUse,
  },
  {
    exact: true,
    name: "register",
    path: REGISTER_ROUTE,
    component: RegisterPage,
  },
  {
    component: NotFoundPage,
  },
];

export const LOGGED_IN_ROUTES = [
  {
    name: "layout",
    path: INDEX_ROUTE,
    component: DashboardLayout,
    routes: [
      {
        exact: true,
        name: "home",
        path: INDEX_ROUTE,
        component: HomePageComponent,
      },
      {
        exact: true,
        name: "locations",
        path: LOCATIONS_ROUTE,
        component: LocationsPageComponent,
      },
      {
        exact: true,
        name: "login",
        path: DIRECT_USE_ROUTE,
        component: DirectUse,
      },
      {
        exact: true,
        name: "buildings",
        path: BUILDINGS_ROUTE,
        component: BuildingsPageComponent,
      },
      {
        exact: true,
        name: "shelves",
        path: SHELVES_ROUTE,
        component: ShelvesPageComponent,
      },
      {
        exact: true,
        name: "bins",
        path: BINS_ROUTE,
        component: BinsPageComponent,
      },
      {
        exact: true,
        name: "products",
        path: PRODUCTS_ROUTE,
        component: ProductsPageComponent,
      },
      {
        exact: true,
        name: "received-trucks",
        path: DELIVERY_LOADS_ROUTE,
        component: DeliveryLoadsPageComponent,
      },
      {
        exact: true,
        name: "vendors",
        path: VENDORS_ROUTE,
        component: VendorsPageComponent,
      },
      {
        exact: true,
        name: "product-discount-rules",
        path: PRODUCT_DISCOUNT_RULES_ROUTE,
        component: ProductDiscountRulesPageComponent,
      },
      {
        exact: true,
        name: "inventory-groups",
        path: INVENTORY_GROUPS_ROUTE,
        component: InventoryGroupsPageComponent,
      },
      {
        exact: true,
        name: "preprocess-inventory",
        path: RECEIVING_ROUTE,
        component: ReceivingItemsPageComponent,
      },
      {
        exact: true,
        name: "pallets",
        path: PALLETS_ROUTE,
        component: PalletsPageComponent,
      },
      {
        exact: true,
        name: "zones",
        path: ZONES_ROUTE,
        component: ZonesPageComponent,
      },
      {
        exact: true,
        name: "inventory-zpl-settings",
        path: INVENTORY_ZPL_SETTINGS_ROUTE,
        component: InventoryZPLSettingsPageComponent,
      },
      {
        exact: true,
        name: "discount-pricing-rules",
        path: DISCOUNT_PRICING_RULES_ROUTE,
        component: DiscountPricingRulesPageComponent,
      },
      {
        exact: true,
        name: "outgoing-trucks",
        path: OUTGOING_TRUCKS_ROUTE,
        component: OutgoingTrucksPageComponent,
      },
      {
        exact: true,
        name: "inventory-group-summary-view",
        path: INVENTORY_GROUP_SUMMARY_VIEW_ROUTE,
        component: InventoryGroupSummaryView,
      },
      {
        exact: true,
        name: "inventory-settings",
        path: INVENTORY_SETTINGS_ROUTE,
        component: InventorySettingsPageComponent,
      },
      {
        exact: true,
        name: "product-types",
        path: INVENTORY_PRODUCT_TYPES_ROUTE,
        component: InventoryProductTypesPageComponent,
      },
      {
        exact: true,
        name: "inventory-item-field-configs",
        path: INVENTORY_ITEM_FIELD_CONFIGS_ROUTE,
        component: InventoryItemFieldConfigsPageComponent,
      },
    ],
  },
  {
    component: NotFoundPage,
  },
];

export const SIDEBAR_ITEMS = [
  {
    name: "home",
    label: "Dashboard",
    route: INDEX_ROUTE,
    icon: <DashboardOutlined />,
  },
  {
    name: "inventory",
    label: "Inventory",
    icon: <CodeSandboxOutlined />,
    submenu: [
      {
        name: "inventory-items",
        label: "Inventory Items",
        route: PRODUCTS_ROUTE,
        icon: <CodeSandboxOutlined />,
      },
      {
        name: "pallets",
        label: "Pallets",
        route: PALLETS_ROUTE,
        icon: <CodeSandboxOutlined />,
      },
      {
        name: "preprocess-inventory",
        label: "Pre-Process Inventory",
        route: RECEIVING_ROUTE,
        icon: <AuditOutlined />,
      },
      {
        name: "inventory-groups",
        label: "Inventory Groups",
        route: INVENTORY_GROUPS_ROUTE,
        icon: <SnippetsOutlined />,
      },
      {
        name: "received-trucks",
        label: "Received Trucks",
        route: DELIVERY_LOADS_ROUTE,
        icon: <CarOutlined />,
      },
      {
        name: "outgoing-trucks",
        label: "Outgoing Trucks",
        route: OUTGOING_TRUCKS_ROUTE,
        icon: <CarOutlined />,
      },
      {
        name: "product-types",
        label: "Product Types",
        route: INVENTORY_PRODUCT_TYPES_ROUTE,
        icon: <CodeSandboxOutlined />,
      },
    ],
  },
  {
    name: "location-settings",
    label: "Location Settings",
    icon: <ShopOutlined />,
    submenu: [
      {
        name: "locations",
        label: "Locations",
        route: LOCATIONS_ROUTE,
        icon: <ShopOutlined />,
      },
      {
        name: "buildings",
        label: "Buildings",
        route: BUILDINGS_ROUTE,
        icon: <HomeOutlined />,
      },
      {
        name: "shelves",
        label: "Shelves",
        route: SHELVES_ROUTE,
        icon: <DatabaseOutlined />,
      },
      {
        name: "bins",
        label: "Bins",
        route: BINS_ROUTE,
        icon: <InboxOutlined />,
      },
      {
        name: "zones",
        label: "Zones",
        route: ZONES_ROUTE,
        icon: <InboxOutlined />,
      },
    ],
  },
  {
    name: "downloads",
    label: "Download(s)",
    icon: <CloudDownloadOutlined />,
    submenu: [
      {
        name: "download-receiving",
        label: "Pre-Process Items",
        icon: <CloudDownloadOutlined />,
        downloadReceiving: true,
      },
      {
        name: "download-inventory",
        label: "Inventory Item(s)",
        icon: <CloudDownloadOutlined />,
        downloadInventoryItems: true,
      },
      {
        name: "download-pallets",
        label: "Pallets",
        icon: <CloudDownloadOutlined />,
        downloadPallets: true,
      },
      {
        name: "download-outgoing-truck",
        label: "Outgoing Truck",
        icon: <CloudDownloadOutlined />,
        downloadOutgoingTruck: true,
      },
    ],
  },
  {
    name: "discount-settings",
    label: "Discount Settings",
    icon: <PercentageOutlined />,
    submenu: [
      {
        name: "product-discount-rules",
        label: "Discount Engine",
        route: PRODUCT_DISCOUNT_RULES_ROUTE,
        icon: <PercentageOutlined />,
      },
      {
        name: "discount-pricing-rules",
        label: "Discount Rules",
        route: DISCOUNT_PRICING_RULES_ROUTE,
        icon: <PercentageOutlined />,
      },
    ],
  },
  {
    name: "vendors",
    label: "Vendors",
    route: VENDORS_ROUTE,
    icon: <ShopOutlined />,
  },
  {
    name: "inventory-item-field-configs",
    label: "Inventory View Settings",
    route: INVENTORY_ITEM_FIELD_CONFIGS_ROUTE,
    icon: <SettingOutlined />,
  },
  {
    name: "inventory-zpl-settings",
    label: "Inventory ZPL Settings",
    route: INVENTORY_ZPL_SETTINGS_ROUTE,
    icon: <PrinterOutlined />,
  },
  {
    name: "inventory-settings",
    label: "Inventory Settings",
    route: INVENTORY_SETTINGS_ROUTE,
    icon: <SettingOutlined />,
  },
];
