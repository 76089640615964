import qs from "query-string";
import loggerServiceInstance from "./utilities/loggerService";

export const DollarFormatter = (value) => {
  return `$ ${roundNumber(value, 2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // below is zero padding to decimal is added if necessary
  // that however causes an issue with the entry because entering 1 will prefill it fully and prevent input unless manually moving the cursor
  if (value === "") {
    return "";
  }
  try {
    // the below function will format the value to a currency format
    // zero padding to decimal is added if necessary

    // Convert value to number with maximum of 2 decimal places
    value = parseFloat(value).toFixed(2);

    if (value === "NaN") {
      return "";
    }

    // Separate integer part from decimal part
    var parts = value.split(".");
    var integerPart = parts[0];
    var decimalPart = parts.length > 1 ? "." + parts[1] : "";

    // Add comma separators for thousands
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Add zero padding if necessary
    if (decimalPart.length === 0) {
      decimalPart = ".00";
    } else if (decimalPart.length === 2) {
      decimalPart += "0";
    }

    // Return formatted currency
    return "$ " + integerPart + decimalPart;
  } catch {
    return `$ ${roundNumber(value, 2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const roundNumber = (number, decimals = 12) => {
  var newNumber = Number(number + "").toFixed(parseInt(decimals));
  return parseFloat(newNumber);
};

export const DollarParser = (value) => {
  return value.replace(/\$\s?|(,*)/g, "");
};

export const updateUrlQueryString = (newValues, search, history) => {
  const values = qs.parse(search);
  const newQsValue = qs.stringify({
    ...values,
    ...newValues,
  });
  history.push({
    search: newQsValue,
    pathname: history.location.pathname,
  });
};

export const nicelyFormatPascalCaseString = (
  camelCaseString,
  overrideInvalidStringValue = "N/A"
) => {
  if (camelCaseString === null || camelCaseString === undefined)
    return overrideInvalidStringValue;
  return (
    camelCaseString
      // insert a space before all caps
      .replace(/([A-Z])/g, " $1")
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
  );
};

export const HardReloadFrontend = () => {
  navigator.serviceWorker.getRegistrations().then((registrations) => {
    registrations.forEach((registration) => {
      registration.unregister();
    });
  });
  caches.keys().then((keyList) => {
    return Promise.all(
      keyList.map((key) => {
        return caches.delete(key);
      })
    );
  });
  setTimeout(() => {
    window.location.reload();
  }, 900);
};

export const SelectSearchByLabel = (input, option) => {
  return (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
};

const tableNameFilter = (x, tableName) => {
  if (tableName) {
    return x.table_name === tableName;
  }
  return true;
};
const findMatchingItemFieldConfig = (
  itemFieldConfigs,
  model,
  fieldName,
  tableName
) => {
  const matchedItemFieldConfig = (itemFieldConfigs || []).find(
    (x) =>
      x.type_of_model === model &&
      x.field_name === fieldName &&
      tableNameFilter(x, tableName)
  );

  return matchedItemFieldConfig;
};

export const DetermineVisibility = (
  fieldName,
  model,
  itemFieldConfigs,
  viewMode, // "Add", "Edit", "View", "AfterScan"
  tableName = null
) => {
  const matchedItemFieldConfig = findMatchingItemFieldConfig(
    itemFieldConfigs,
    model,
    fieldName,
    tableName
  );
  if (!matchedItemFieldConfig) {
    if ((itemFieldConfigs || []).length > 0) {
      loggerServiceInstance.log("Field not found in itemFieldConfigs", {
        fieldName,
        model,
        itemFieldConfigs,
      });
    }

    return false;
  }

  switch (viewMode) {
    case "Add":
      return matchedItemFieldConfig?.is_add_visible;
    case "Edit":
      return matchedItemFieldConfig?.is_edit_visible;
    case "View":
      return matchedItemFieldConfig?.is_view_visible;
    case "AfterScan":
      return matchedItemFieldConfig?.is_after_scan_visible;
    default:
      break;
  }
  loggerServiceInstance.log("Invalid viewMode", { viewMode });
  return false;
};
export const DetermineRequiredness = (
  fieldName,
  model,
  itemFieldConfigs,
  viewMode, // "Add", "Edit", "View", "AfterScan"
  tableName = null
) => {
  const matchedItemFieldConfig = findMatchingItemFieldConfig(
    itemFieldConfigs,
    model,
    fieldName,
    tableName
  );
  if (!matchedItemFieldConfig) {
    if ((itemFieldConfigs || []).length > 0) {
      loggerServiceInstance.log("Field not found in itemFieldConfigs", {
        fieldName,
        model,
        itemFieldConfigs,
      });
    }

    return false;
  }

  switch (viewMode) {
    case "Add":
      return matchedItemFieldConfig?.is_add_required;
    case "Edit":
      return matchedItemFieldConfig?.is_edit_required;
    case "View":
      return matchedItemFieldConfig?.is_view_required;
    case "AfterScan":
      return matchedItemFieldConfig?.is_after_scan_required;
    default:
      break;
  }
  loggerServiceInstance.log("Invalid viewMode", { viewMode });
  return false;
};

export const getContrastColor = (bgColor) => {
  // Convert hex color to RGB
  const r = parseInt(bgColor.substr(1, 2), 16);
  const g = parseInt(bgColor.substr(3, 2), 16);
  const b = parseInt(bgColor.substr(5, 2), 16);
  // Calculate luminance
  const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  // Return black or white depending on luminance
  return luminance > 0.5 ? "#000000" : "#FFFFFF";
};
