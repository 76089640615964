import { PALLET_TYPES, PALLET_TYPES_OPTIONS } from "./constants";

export const defaultPagination = {
  current: 1,
  pageSize: 10,
  pageSizeOptions: ["10", "20", "25"],
};

export const displayDateTimeFormat = "MM/DD/YYYY hh:mm A";
export const displayDateFormat = "MM/DD/YYYY";
export const displayNoYearDateTimeFormat = "MM/DD hh:mm A";
export const chatDisplayDateTimeFormat = "MM/DD hh:mm A";
export const backendDisplayDateTimeFormat = "YYYY-MM-DD HH:mm:ss";

export const AUTHORIZATION_KEY = "mfs-inventory-token";
export const DEVICE_ID = "manyfastScan-inventory-device-id";
export const DASHBOARD_ACTIVE_KEY = "dashboard-key";
export const ACCOUNT_ID_KEY = "account-id";

export const DEFAULT_PAGINATION = {
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
};

export const DATE_STRING_FORMAT_BACKEND = "YYYY-MM-DD";

export const DISPLAY_FULL_DATETIME_FORMAT = "MM/DD/YYYY hh:mm A";

export const DATETIME_BACKEND_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export const IMAGE_FILE_TYPES = ["image/png", "image/jpeg", "image/jpg"];

export const ZPL_VARIABLES_SHARED = [
  {
    variable: "{LABEL_PRINT_DATE}",
    name: "Label Print Date",
    description: "The date the label was printed - formated as MM/DD/YYYY",
  },
  {
    variable: "{LABEL_PRINT_DATE_TIME}",
    name: "Label Print Date & Time",
    description:
      "The date and time the label was printed - formated as MM/DD/YYYY HH:mm:ss",
  },
];

export const INVENTORY_ITEM_ZPL_VARIABLES = [
  ...ZPL_VARIABLES_SHARED,
  {
    variable: "{ProductItem.Id}",
    name: "Product Item ID",
    description:
      "The ID of the Product Item. Internal MFS Inventory ID, which is read only (users cannot change it).",
  },
];

export const RETAIL_ITEM_ZPL_VARIABLES = [
  ...ZPL_VARIABLES_SHARED,
  {
    variable: "{ProductItem.Id}",
    name: "Product Item ID",
    description:
      "The ID of the Product Item. Internal MFS Inventory ID, which is read only (users cannot change it).",
  },
];

export const RECEIVING_INVENTORY_ITEM_ZPL_VARIABLES = [
  ...ZPL_VARIABLES_SHARED,
  {
    variable: "{ProductItem.Id}",
    name: "Product Item ID",
    description:
      "The ID of the Product Item. Internal MFS Inventory ID, which is read only (users cannot change it).",
  },
];

export const PALLET_ITEM_ZPL_VARIABLES = [
  ...ZPL_VARIABLES_SHARED,
  {
    variable: "{Pallet.Id}",
    name: "Pallet ID",
    description:
      "The ID of the Pallet. Internal MFS Inventory ID, which is read only (users cannot change it).",
  },
  {
    variable: "{Pallet.PalletNumber}",
    name: "Pallet Number",
    description: "The Pallet Number",
  },
  {
    variable: "{Pallet.DeliveryLoad.UniqueIdentifier}",
    name: "Received Truck Unique Identifier",
    description: "The Unique Identifier of the Received Truck (if assigned)",
  },
  {
    variable: "{Pallet.Vendor.Name}",
    name: "Vendor Name",
    description: "The Name of the Vendor (if assigned)",
  },
  {
    variable: "{Pallet.ManifestGroup.Name}",
    name: "Manifest Group Name",
    description: "The Name of the Manifest Group (if assigned)",
  },
  {
    variable: "{Pallet.ProductType}",
    name: "Product Type",
    description: "The Product Type of the Pallet (if set)",
  },
  {
    variable: "{Pallet.Zone}",
    name: "Zone",
    description: "The Zone of the Pallet (if set)",
  },
  {
    variable: "{Pallet.Type}",
    name: "Pallet Type",
    description: `The Type of the Pallet - ${PALLET_TYPES_OPTIONS}`,
  },
  {
    variable: "{Pallet.CountPalletsFromReceivedTruck}",
    name: "# of Pallets From Received Truck",
    description: "The Count of Pallets from the Received Truck",
  },
];
