import { useState } from "react";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input, Button, Checkbox, Card, message } from "antd";

import { loginUser } from "../../actions";
import {
  ACCOUNT_ID_KEY,
  AUTHORIZATION_KEY,
  DASHBOARD_ACTIVE_KEY,
  DEVICE_ID,
} from "../../global_constants";

import AccountHook from "../../hooks/account";
import { Link } from "react-router-dom";

const Login = ({ setAccountData }) => {
  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);

  function onSubmit(values) {
    setProcessing(true);
    loginUser(values)
      .then((response) => {
        setProcessing(false);

        // check if there is account id in the localStorage
        // if there is, check if the account id is the same
        // if it is, do nothing
        // if it isn't, reset all the localStorage values except the base ones
        // this is to prevent users using multiple accounts and having the wrong preselects
        // pallets, etc.
        const accountId = localStorage.getItem(ACCOUNT_ID_KEY);
        if (!accountId || accountId !== response.accountId) {
          const baseKeyNames = [AUTHORIZATION_KEY, DEVICE_ID];
          for (const baseKeyName in localStorage) {
            // if the key is not a base key, remove it
            if (!baseKeyNames.includes(baseKeyName)) {
              localStorage.removeItem(baseKeyName);
            }
          }
        }

        localStorage.setItem(ACCOUNT_ID_KEY, response.accountId);
        localStorage.setItem(AUTHORIZATION_KEY, response.token);
        localStorage.setItem(DEVICE_ID, response.device_id);

        setAccountData(response);
      })
      .catch((err) => {
        setProcessing(false);
        message.error(err?.response?.data?.detail);
      });
  }

  return (
    <div className="login-page">
      <Card bordered>
        <p className="text-2xl semi-bold mb-16">Login</p>
        <Form form={form} onFinish={(values) => onSubmit(values)}>
          <Form.Item
            name="username"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input
              size="middle"
              prefix={<UserOutlined />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your Password!",
              },
            ]}
          >
            <Input.Password
              size="middle"
              prefix={<LockOutlined />}
              placeholder="Password"
            />
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item noStyle>
            <Button
              block
              type="primary"
              className="mt-8"
              htmlType="submit"
              loading={processing}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
        <p
          style={{
            marginTop: "15px",
          }}
        >
          Don't have an Account? Click <Link to={"/register"}>Here</Link> to
          Register new Account
        </p>
      </Card>
    </div>
  );
};
export default AccountHook(Login);
